import type { NextResponse } from 'next/server';
import type { CookieSerializeOptions } from 'cookie';

import Cookies from 'js-cookie';

export type UtmParams = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_referrer?: string;
};

export const UTM_COOKIE_NAME = 'LEAFWELL_UTM';

export const DEFAULT_UTM_PARAMS = {
  utm_source: 'leafwell',
  utm_medium: 'website',
  utm_campaign: 'internal',
};

export function getCookieSettings(): CookieSerializeOptions {
  const expirationDate = new Date();

  expirationDate.setDate(expirationDate.getDate() + 30);

  return {
    expires: expirationDate,
    secure: process.env.NODE_ENV !== 'development',
  };
}

export function geUrlParams(params: URLSearchParams): UtmParams {
  const utm: UtmParams = {};

  for (const [key, value] of params) {
    if (key.startsWith('utm_') && key !== 'utm_referrer') {
      utm[key] = value;
    }
  }

  return utm;
}

export function getUtmCookieClient(): UtmParams | null {
  const value = Cookies.get(UTM_COOKIE_NAME);

  return value ? JSON.parse(value) : null;
}

export function setUtmCookieServer(
  res: NextResponse,
  params?: URLSearchParams,
): NextResponse {
  const utm = geUrlParams(params);

  if (Object.keys(utm).length) {
    res.cookies.set(
      UTM_COOKIE_NAME,
      JSON.stringify(geUrlParams(params)),
      getCookieSettings(),
    );
    const cookie = res.cookies.get(UTM_COOKIE_NAME);
  }

  return res;
}
