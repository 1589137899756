import { useMemo } from 'react';

import {
  DEFAULT_UTM_PARAMS,
  getUtmCookieClient,
  geUrlParams,
} from 'lib/cookies/utm-cookie';

export type UtmParamsOptional = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_referrer?: string;
};

// utm inheritance order
// cookie utm -> prop utm -> default utm

export function useCurrentUtm(
  customUtm?: UtmParamsOptional,
): UtmParamsOptional {
  if (typeof window === 'undefined') {
    return {};
  }

  const utmCookie = getUtmCookieClient();

  return useMemo(() => {
    const temporaryUtmReferrer = new URL(
      document.location.href,
    ).searchParams.get('utm_referrer');

    const utm_referrer = temporaryUtmReferrer
      ? { utm_referrer: temporaryUtmReferrer }
      : document.referrer &&
        !new URL(document.referrer).pathname.startsWith('/medical-card')
      ? { utm_referrer: document.referrer }
      : {};

    return {
      ...DEFAULT_UTM_PARAMS,
      ...utm_referrer,
      ...customUtm,
      ...utmCookie,
    };
  }, [customUtm, utmCookie]);
}

export function getAppUtm(): boolean {
  if (typeof window !== 'undefined') {
    const params = geUrlParams(new URL(window?.location.href).searchParams);

    if (params?.utm_source === 'app') {
      return true;
    }
  }
  return false;
}
