import React from 'react';
import { useRouter } from 'next/router';

export function getActivePage(href: string, currentPath: string): boolean {
  // we have several medical card state pages but the one rendered on the menu is always with a href of `/medical-card` and if
  // a state is already selected (on cookie) we need this check so we can have the active item on menu.
  if (currentPath.startsWith('/medical-card') && currentPath.includes(href)) {
    return true;
  }

  if (currentPath.includes(href) && href.includes(currentPath)) {
    return true;
  }

  return false;
}

export function useActivePage(href: string): boolean {
  const { asPath } = useRouter();
  const isActivePage = React.useMemo(
    () => getActivePage(href, asPath.split('?')[0]), // remove query params
    [asPath, href],
  );

  return isActivePage;
}
