import type { ChildItem, HeaderMenu, MenuDetails } from 'types/settings';
import type { Media } from 'types/media';
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { Badge, Link, Paragraph } from '@leafwell/components';
import { getActivePage, useActivePage } from 'hooks/useActivePage';
import Figure from './figure';
import { useEventsTracker } from 'contexts/eventsTracker';
import { useWordPressContext } from 'contexts/wordpress-provider';

function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

function getSubMenuColumns(menuDetails: MenuDetails) {
  return menuDetails?.ctas?.reduce(count => count + 1, 0) || 0;
}

const SubMenuLink: React.FC<
  Omit<ChildItem, 'title'> & {
    title?: string;
    className?: string;
  }
> = ({ className = '', title, submenuDetails, url }) => {
  const isCurrentPage = useActivePage(url);
  const itemVariant = useMemo(() => {
    if (isCurrentPage) {
      return 'bgHighlight';
    }

    return 'bgHover';
  }, [isCurrentPage]);
  const { trackEvent } = useEventsTracker();

  return (
    <Link
      href={url}
      target="_self"
      variant={itemVariant}
      className={[
        'grid grid-cols-[auto_1fr] gap-x-1 xl:gap-x-2 xl:grid-cols-1 xl:grid-rows-[auto_1fr_auto] xl:justify-between xl:gap-y-5 h-full p-5',
        'border xl:border-0 rounded-lg',
        className,
      ].join(' ')}
      onClick={() =>
        trackEvent({
          event: 'select_content',
          content_type: title,
          item_id: 'page',
          origin: 'main_menu',
        })
      }
    >
      {submenuDetails?.icon && (
        <Figure
          {...{
            ...submenuDetails?.icon,
            className:
              'w-10 h-10 mr-2 xl:mr-0 flex justify-center items-center',
            classNameImage: '!w-auto',
          }}
        />
      )}
      <div className="">
        <h4
          className="leading-tight xl:text-lg"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h4>
        {submenuDetails?.subTitle && (
          <Paragraph
            size="xsmall"
            className={['xl:block xl:mt-0.5'].join(' ')}
            tagName="span"
          >
            {submenuDetails.subTitle}
          </Paragraph>
        )}
      </div>
      <Image
        width={15}
        height={12}
        src="/arrow.svg"
        alt=""
        className={['hidden xl:block'].join(' ')}
      />
    </Link>
  );
};

const MainMenuLink: React.FC<{
  url?: string;
  title?: string;
  badge?: string;
  className?: string;
  disableActiveStyles?: boolean;
  hasActiveItem?: boolean;
  hasSubmenu?: boolean;
  icon?: Omit<Media, 'id' | 'srcSet'>;
  onSubMenu?: (item: string) => void;
}> = ({
  badge,
  className = '',
  disableActiveStyles = false,
  hasActiveItem,
  hasSubmenu,
  title,
  url,
  icon,
  onSubMenu,
}) => {
  const isCurrentPage = useActivePage(url);
  const isActive = disableActiveStyles ? false : isCurrentPage || hasActiveItem;
  const { trackEvent } = useEventsTracker();

  return (
    <div
      className={[
        'leafwell-menu-link relative underline-offset-small cursor-pointer transition-all duration-200',
        'focus:outline-none outline-none no-underline',
        'hover:bg-lilac-50 xl:p-3 xl:px-2 rounded-md',
        'grid grid-flow-col  items-center justify-between xl:justify-start justify-items-start',
        'text-2xl xl:font-medium xl:text-base',
        isActive
          ? 'underline underline-green-400 underline-offset-medium underline-thickness-medium xl:no-underline'
          : '',
        className,
      ].join(' ')}
    >
      {badge?.length > 0 && (
        <Badge
          {...{
            className:
              'xl:justify-self-end xl:z-20 py-0 px-1 border border-black xl:absolute xl:bottom-full',
            label: badge,
            size: 'xsmall',
            variant: 'white',
          }}
        />
      )}
      <a
        className={['relative flex items-center gap-x-4 leading-6'].join(' ')}
        href={url}
        target="_self"
        onClick={() =>
          trackEvent({
            event: 'select_content',
            content_type: title,
            item_id: 'page',
            origin: 'main_menu',
          })
        }
      >
        {icon && <Figure {...icon} className="w-10 xl:hidden" />}
        {title}
        {isActive && (
          <Image
            src="/menu-line.svg"
            alt=""
            className="hidden xl:block absolute left-2/4 top-full transform -translate-x-1/2"
            width={89}
            height={7}
          />
        )}
      </a>
      {hasSubmenu && (
        <Image
          src="/chevron.svg"
          width={24}
          height={24}
          className="hidden xl:block"
          alt=""
        />
      )}
      {hasSubmenu && (
        <button
          className="p-3 xl:p-0"
          aria-label="Open submenu"
          onClick={e => {
            e.preventDefault();
            onSubMenu(title);
          }}
        >
          <Image
            className="w-5 xl:hidden"
            src="/arrow.svg"
            width={20}
            height={16}
            alt=""
          />
        </button>
      )}
    </div>
  );
};

const SubMenuBlocks: React.FC<MenuDetails & { length: number }> = ({
  ctas,
  length,
}) => {
  let positionClass: string;

  switch (length) {
    case 3:
      positionClass = 'xl:grid-cols-3 xl:col-start-4 xl:col-span-3';
      break;
    default:
      positionClass = 'xl:grid-cols-2 xl:col-start-5 xl:col-span-2';
      break;
  }

  const { trackEvent } = useEventsTracker();

  return (
    <li
      className={['hidden xl:grid xl:gap-x-5 xl:gap-y-5', positionClass].join(
        ' ',
      )}
    >
      {ctas.map(({ button, image }, index) => {
        const gridClasses = ctas.length === 1 ? 'xl:col-span-2' : '';
        return (
          <a
            key={index}
            className={['grid', gridClasses].join(' ')}
            href={button?.url}
            onClick={() => {
              trackEvent({
                event: 'select_content',
                content_type: button?.title,
                item_id: 'page',
                origin: 'main_menu',
              });
            }}
          >
            {image && <Figure {...image} className="rounded-lg" quality={50} />}
            {button && (
              <span className="relative underline-offset-small cursor-pointer transition-all duration-200 hover:underline group flex justify-between items-center text-sm font-sans">
                {button?.title}
                <Image
                  width={15}
                  height={12}
                  src="/arrow.svg"
                  alt=""
                  className="duration-500"
                />
              </span>
            )}
          </a>
        );
      })}
    </li>
  );
};

const MainMenu: React.FC<{ headerMenu: HeaderMenu; menuVisible?: boolean }> = ({
  headerMenu: items,
  menuVisible,
}) => {
  const { asPath } = useRouter();
  const { translations } = useWordPressContext();
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  useEffect(() => {
    if (!menuVisible && activeSubMenu) {
      setActiveSubMenu(null);
    }
  }, [menuVisible]);

  return (
    <nav className="xl:mx-auto">
      <Paragraph size="small" className="my-4 xl:hidden">
        {translations['Menu']}
        {activeSubMenu !== null && (
          <>
            <span className="px-2">&gt;</span>
            {activeSubMenu}
          </>
        )}
      </Paragraph>

      <ul className="grid xl:grid-flow-col xl:items-center md:gap-x-2 2xl:gap-x-4 gap-y-6">
        {items.map(({ childItems, menuDetails, title, url }) => {
          const hasSubmenu = !isEmpty(childItems);
          const hasActiveItem =
            hasSubmenu &&
            childItems.some(({ url }) => getActivePage(url, asPath));
          const extraContentLength = getSubMenuColumns(menuDetails);
          const subMenuId = `submenu-title-${title}`;

          return (
            <li
              className={[
                'leafwell-menu-parent focus:outline-none',
                menuDetails?.mobileOnly ? 'lg:hidden' : '',
              ].join(' ')}
              tabIndex={0}
              key={title}
            >
              <MainMenuLink
                title={title}
                url={url}
                badge={menuDetails?.badge}
                className="xl:text-center xl:z-20 xl:leading-tight"
                hasActiveItem={hasActiveItem}
                hasSubmenu={hasSubmenu}
                icon={menuDetails?.icon}
                onSubMenu={item => {
                  setActiveSubMenu(item);
                }}
              />
              {hasSubmenu && (
                <div
                  className={[
                    'leafwell-sub-menu absolute',
                    'top-14 xl:top-22 xl:pt-12 md:-mt-2 z-10 left-0',
                    'inline-grid justify-stretch xl:justify-center items-center h-screen-height-header2x xl:block xl:h-auto',
                    'xl:opacity-0 xl:invisible xl:pointer-events-none',
                    'w-full max-w-screen-2xl overflow-auto xl:overflow-visible bg-white xl:bg-transparent',
                    'px-5 xl:px-0',
                    menuVisible
                      ? [
                          'transition ease-in-out duration-500 ',
                          activeSubMenu === null
                            ? 'translate-x-full xl:translate-x-0 opacity-0'
                            : 'translate-x-0',
                        ].join(' ')
                      : 'translate-x-full xl:translate-x-0',
                  ].join(' ')}
                >
                  <button
                    className="flex gap-x-3 items-center self-start xl:hidden mb-4"
                    onClick={() => setActiveSubMenu(null)}
                  >
                    <Image
                      className="w-5 rotate-180"
                      src="/arrow.svg"
                      width={20}
                      height={16}
                      alt=""
                    />
                    <Paragraph>{translations['Back']}</Paragraph>
                  </button>
                  <ul
                    aria-describedby={subMenuId}
                    className={[
                      'xl:py-2.5 xl:px-2.5 rounded-2xl',
                      'grid content-start xl:gap-x-5 xl:gap-y-5',
                      'xl:grid-cols-6 bg-white',
                      'md:w-95-view-width xl:w-auto xl:border xl:radius-20px xl:border-[#DEE0DE]',
                    ].join(' ')}
                  >
                    {childItems.map(({ title, ...childItemProps }) => (
                      <li key={title} className="mb-2.5 xl:mb-0">
                        <SubMenuLink title={title} {...childItemProps} />
                      </li>
                    ))}
                    {extraContentLength > 0 && (
                      <SubMenuBlocks
                        {...{
                          ...menuDetails,
                          length: extraContentLength,
                        }}
                      />
                    )}
                  </ul>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default MainMenu;
