import { useCallback, useEffect, useReducer } from 'react';

import { getStateCookie } from 'lib/cookies/state-cookie';
import { useWordPressContext } from 'contexts/wordpress-provider';

type UpdateCardPageUrl = (stateCode?: string) => void;

type UseCardPageState = string | undefined;

type UseCardPageUrl = [UseCardPageState, UpdateCardPageUrl];

export function useCardPageUrl(): UseCardPageUrl {
  const stateCookie = getStateCookie();
  const { cardPage, statePages } = useWordPressContext();

  const getCardPageUrl = useCallback(
    (stateCode?: string): UseCardPageState => {
      if (stateCode) {
        return statePages.find(({ code }) => code === stateCode)?.uri;
      }

      return cardPage?.uri;
    },
    [cardPage, statePages],
  );

  const [url, updateUrl] = useReducer(
    (_, newState) => getCardPageUrl(newState),
    '',
  );

  useEffect(() => {
    updateUrl(stateCookie);
  }, [stateCookie]);

  return [url, updateUrl];
}
