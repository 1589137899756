import type { WordpressContext } from 'contexts/wordpress-provider';
import type { AppLocale, WPLanguageCode } from 'lib/queries/settings';

import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Link as LinkComponent } from '@leafwell/components';

import { useWordPressContext } from 'contexts/wordpress-provider';
import { useHomeUrl } from 'hooks/useHomeUrl';
import { locales as NEXT_LOCALES } from '../i18n.mjs';
import { useEventsTracker } from 'contexts/eventsTracker';

function getLanguageSlug(locale: string): WPLanguageCode {
  return Object.keys(NEXT_LOCALES).find(
    key => NEXT_LOCALES[key] === locale,
  ) as WPLanguageCode;
}

const LocaleLink: React.FC<
  Pick<WordpressContext, 'pageTranslations'> & {
    locale: string;
  }
> = ({ locale, pageTranslations }) => {
  const { trackEvent } = useEventsTracker();
  const homeUrl = useHomeUrl(locale);
  const languageSlug = getLanguageSlug(locale as AppLocale);
  const localeTranslation = pageTranslations?.find(
    translation => translation.slug === languageSlug,
  ) || {
    locale,
    uri: homeUrl,
  };

  return (
    <LinkComponent
      {...{
        className: 'uppercase py-1 text-base font-medium hover:text-white',
        href: localeTranslation.uri,
        hrefLang: localeTranslation.locale,
        lang: languageSlug,
        onClick: () =>
          trackEvent({
            event: 'locale_switch',
            content_type: locale,
            item_id: 'locale_switch_button',
            origin: 'main_menu',
          }),
      }}
    >
      {languageSlug}
    </LinkComponent>
  );
};

const LocaleSwitch: React.FC<{ className?: string }> = ({ className = '' }) => {
  const { pageTranslations, translations } = useWordPressContext();
  const { locales, locale: activeLocale } = useRouter();
  const [open, toggleMenu] = useState(false);
  const activeLocaleSlug = getLanguageSlug(activeLocale as AppLocale);

  const styles = 'border border-lilac-300 p-3 w-10 hover:bg-lilac-300';

  return (
    <section
      {...{
        className: ['relative ', className].join(' '),
      }}
    >
      <button
        {...{
          'aria-controls': 'locale-switch',
          'aria-expanded': open,
          'aria-label': translations[' Change language'],
          className: [
            styles,
            'flex justify-center items-center gap-3 uppercase h-10',
            open ? 'bg-white rounded-t-full' : 'rounded-full',
          ].join(' '),
          onClick: () => toggleMenu(!open),
        }}
      >
        <span aria-hidden="true" className="text-base	font-medium">
          {activeLocaleSlug}
        </span>
      </button>
      {open ? (
        <nav
          {...{
            className: [
              styles,
              'bg-white grid gap-2 justify-center absolute top-full pt-1 border-t-0 -translate-y-1',
              open ? 'transition-all rounded-b-full' : 'opacity-0',
            ].join(' '),
            id: 'locale-switch',
          }}
        >
          {locales.map(locale =>
            locale !== activeLocale ? (
              <LocaleLink
                {...{
                  key: locale,
                  locale,
                  pageTranslations,
                }}
              />
            ) : null,
          )}
        </nav>
      ) : null}
    </section>
  );
};

export default LocaleSwitch;
