import { Button, ButtonDefaultProps } from '@leafwell/components';
import Image from 'next/image';
import { useWordPressContext } from 'contexts/wordpress-provider';

const SearchButton: React.FC<
  Omit<ButtonDefaultProps, 'label'> & {
    isLandingPage?: boolean;
    open?: boolean;
  }
> = ({ className, isLandingPage = false, open = false, ...props }) => {
  const { translations } = useWordPressContext();

  return (
    <Button
      label={
        isLandingPage ? (
          translations['Search']
        ) : (
          <Image
            src={open ? '/close.svg' : '/magnifier.svg'}
            className="m-auto"
            width={30}
            height={30}
            alt={translations['Toggle Search'] || ''}
          />
        )
      }
      variant={isLandingPage ? 'secondary' : 'light'}
      className={
        isLandingPage
          ? ['px-12 leading-6 rounded-md justify-self-start'].join(' ')
          : [
              'rounded-full p-reset w-10 h-10 hover:!border-lilac-50 hover:!bg-lilac-50',
              className,
            ].join(' ')
      }
      size="small"
      {...props}
    />
  );
};

export default SearchButton;
